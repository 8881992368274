import React from 'react';

export default class Forum2025 extends React.Component {
    componentDidMount() {
        window.location.href = 'https://forms.gle/W5eZk2usEB9o2TgF9';
    }

    render() {
        return (
            <div></div>
        );
    }
}